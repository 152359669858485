import "./App.css";
import Navbar from "./views/Navbar/navbar";
import Footer from "./views/footer";
import Home from "./views/Home/Home";
import Login from "./views/Login/login";
import UploadBeat from "./views/upload/uploadBeat";
import UploadSong from "./views/upload/uploadSong";
import UploadAlbum from "./views/upload/uploadAlbum";
import UserPage from "./views/user/userPage";
import EditProfile from "./views/editProfile/editProfile";
import BeatsLanding from "./views/landing/beatsLanding";
import Beat from "./views/beat/beat";
import Producer from "./views/producer/producer";
import Checkout from "./views/checkout";
import UpdateBeat from "./views/upload/updateBeat";
import Wallet from "./views/wallet/wallet";
import Accounts from "./views/wallet/accounts";
import Signup from "./views/auth/signup";
import FinishRegistration from "./views/auth/finishRegistration";
import ForgotPW from "./views/auth/forgotPassword";
import ResetPassword from "./views/auth/resetPassword";
import PlayerNew from "./views/Player/player";
import ProducerLanding from "./views/landing/producersLanding";
import Playlists from "./views/playlists/playlists";
import PlaylistLanding from "./views/playlists/playlistLanding";
import CartOverview from "./views/cartOverview";
import AboutUs from "./views/aboutUs";
import Terms from "./views/terms";
import FAQ from "./views/faq";
import Privacy from "./views/privacy";
import Disclaimer from "./views/disclaimer";
import BeatsBid from "./views/beatsBid";
import WithdrawBankAdd from "./views/wallet/withdrawBankAdd";
import WithdrawPaystackAdd from "./views/wallet/withdrawPaystackAdd";
import Purchases from "./views/purchased/purchases";
import Messages from "./views/messages/messages";
import Withdrawals from "./views/wallet/withdrawals";
import WithdrawPaypal from "./views/wallet/paypal";
import WithdrawBank from "./views/wallet/bank";
import WithdrawPaystack from "./views/wallet/paystack";
import Contract from "./views/messages/contract";
import Loading from "./views/helper/loading";
import ConfirmPayment from "./views/helper/confirmPayment";
import Cancel from "./views/helper/cancel";
import NotFound from "./views/helper/notFound";
import ServerError from "./views/helper/serverError";
import TooManyRequests from "./views/helper/tooManyRequests";
import Paystack from "./views/wallet/paystack";
import MusicLanding from "./views/landing/musicLanding";
import MusicLandingDetailed from "./views/landing/musicLandingDetailed";
import Popup from "reactjs-popup";
import Meta from "./views/helper/meta";
import EditAlbum from "./views/upload/editAlbum";
import AlbumsLanding from "./views/landing/albumsLanding";
import Album from "./views/album/album";
import Music from "./views/music/music";
import Artist from "./views/artist";
import EditSong from "./views/upload/editSong";
import FeaturedArtistLanding from "./views/musicLanding/featuredArtistsLanding";
import ProductNotFound from "./views/helper/productNotFound";
import UploadSuccess from "./views/upload/uploadSuccess";
import UploadFailure from "./views/upload/uploadFailure";
import Unverified from "./views/helper/unverified";
import ScrollToTop from "./views/helper/Scroll";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAuthContext } from "./context/authContext";
import { useEffect, useState } from "react";
import { NavigationProvider } from "./context/navigationContext";
import * as Sentry from "@sentry/react";
import api from "./utils/api";
import { usePlayerContext } from "./context/playerContext";
import { playStarting } from "./actions/playerActions";

const musicPagesRoutes = [
  { path: "/music/all", type: "all" },
  { path: "/music/trending", type: "trending" },
  { path: "/music/home", type: "home" },
  { path: "/music/featured", type: "featured" },
];

const albumPagesRoutes = [
  { path: "/albums/all", type: "all" },
  { path: "/albums/trending", type: "trending" },
  { path: "/albums/featured", type: "featured" },
  { path: "/albums/home", type: "home" },
];

function App() {
  const { user, isAuthenticated } = useAuthContext();

  const [showUnauthenticatedPopup, setShowUnauthenticatedPopup] =
    useState(false);
  const [typeUpload, setTypeUpload] = useState(null);
  const uploadSuccess = (type) => {
    setTypeUpload(type);
  };

  const [updateCartCounter, setUpdateCartCounter] = useState(0);
  const handleLocalCart = async (item, type, price) => {
    if (localStorage.getItem("cart")) {
      const cart = JSON.parse(localStorage.getItem("cart"));
      const desiredObject1 = cart.data.find((obj) => {
        return (
          obj.item.id.toString() === item.id.toString() && obj.type === type
        );
      });
      if (desiredObject1) {
        return "Already Present";
      } else {
        const itemObject = {
          item: item,
          type: type,
          price: price,
        };
        cart.data.push(itemObject);
        localStorage.setItem("cart", JSON.stringify(cart));
        return "success";
      }
    } else {
      const itemObject = {
        item: item,
        type: type,
        price: price,
      };
      const cart = {
        data: [itemObject],
      };
      localStorage.setItem("cart", JSON.stringify(cart));
      return "success";
    }
  };
  const handleCart = async (id, type, price) => {
    if (isAuthenticated) {
      let body = {};
      if (price) {
        body[type] = id;
        body.price = price;
      } else {
        body[type] = id;
      }

      try {
        const response = await api.post("cart/add", body);
        return response;
      } catch (error) {}
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (localStorage.getItem("token")) {
      const access_token = JSON.parse(localStorage.getItem("token"));
      const token = access_token.access_token;
      const expirationTime = access_token.expires_in;

      if (token && expirationTime) {
        const currentTime = Date.now();
        if (currentTime > expirationTime) {
          localStorage.removeItem("token");
          localStorage.removeItem("playing_queue");
          window.dispatchEvent(new Event("storage"));
          window.location.href = "/";
        } else {
          const timeUntilExpiration = expirationTime - currentTime;
          const logoutTimer = setTimeout(() => {
            if (localStorage.getItem("keep_signed_in") === "true") {
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("playing_queue");
              window.dispatchEvent(new Event("storage"));

              window.location.href = "/";
            }
          }, timeUntilExpiration);

          return () => clearTimeout(logoutTimer);
        }
      }
    }
  }, []);

  const { playerDispatch } = usePlayerContext();

  useEffect(() => {
    const getBeats = async function () {
      try {
        const response = await api.get("songs");

        const data = response.data;

        await playerDispatch(playStarting(data.data[data.data.length - 1]));
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    getBeats();
  }, [playerDispatch]);

  return (
    <>
      <Meta />
      <Router>
        <ScrollToTop />
        <div>
          <NavigationProvider>
            <Navbar
              user={user}
              isAuthenticated={isAuthenticated}
              setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
              updateCartCounter={updateCartCounter}
            />
            <div>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <Home
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  exact
                  path="/artists/featured"
                  element={<FeaturedArtistLanding />}
                />
                <Route
                  exact
                  path="/music"
                  element={
                    <MusicLanding
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                {musicPagesRoutes.map((route) => (
                  <Route
                    exact
                    key={route.path}
                    path={route.path}
                    element={
                      <MusicLandingDetailed
                        type={route.type}
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        updateCartCounter={updateCartCounter}
                        setUpdateCartCounter={setUpdateCartCounter}
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                ))}
                {albumPagesRoutes.map((route) => (
                  <Route
                    exact
                    key={route.path}
                    path={route.path}
                    element={
                      <AlbumsLanding
                        type={route.type}
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        updateCartCounter={updateCartCounter}
                        setUpdateCartCounter={setUpdateCartCounter}
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                ))}
                <Route
                  path="/paystack"
                  element={
                    <Paystack
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                    />
                  }
                />
                <Route path="/error/notfound" element={<NotFound />} />
                <Route
                  path="/error/notfound/product"
                  element={<ProductNotFound typeUpload={typeUpload} />}
                />
                <Route path="/error/server" element={<ServerError />} />
                <Route path="/error/requests" element={<TooManyRequests />} />
                <Route
                  path="/beats"
                  element={
                    <BeatsLanding
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/producers" element={<ProducerLanding />} />
                {!isAuthenticated && (
                  <Route
                    path="/login"
                    element={
                      <Login
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                {!isAuthenticated && (
                  <Route
                    path="/signup"
                    element={
                      <Signup
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                <Route
                  path="/signup/finish"
                  element={
                    <FinishRegistration
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                    />
                  }
                />
                <Route
                  path="/upload/beat"
                  element={
                    <UploadBeat
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      uploadSuccess={uploadSuccess}
                    />
                  }
                />
                <Route path="/error/unverified" element={<Unverified />} />
                <Route
                  path="/upload/song"
                  element={
                    <UploadSong
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      uploadSuccess={uploadSuccess}
                    />
                  }
                />
                <Route
                  path="/upload/album"
                  element={
                    <UploadAlbum
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      uploadSuccess={uploadSuccess}
                    />
                  }
                />
                <Route
                  path="/upload/success"
                  element={
                    <UploadSuccess
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      typeUpload={typeUpload}
                    />
                  }
                />
                <Route
                  path="/upload/failure"
                  element={
                    <UploadFailure
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      typeUpload={typeUpload}
                    />
                  }
                />
                {
                  <Route
                    path="/auth/callback/google"
                    element={
                      <Loading
                        message={
                          "Please Wait you will be redirected to the page"
                        }
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                }
                {
                  <Route
                    path="/confirm_payment/:type"
                    element={
                      <ConfirmPayment
                        message={
                          "Please Wait while the payment is being processed"
                        }
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                }
                {
                  <Route
                    path="/cancel/:type"
                    element={
                      <Cancel
                        message={
                          "Please Wait while the payment is being processed"
                        }
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                }
                <Route
                  path="/user"
                  element={
                    <UserPage
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/user/uploads/:type"
                  element={
                    <UserPage
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/user/purchased/:type"
                  element={
                    <UserPage
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/profile/edit"
                  element={
                    <EditProfile
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                    />
                  }
                />
                {isAuthenticated && (
                  <Route
                    path="/accounts"
                    element={
                      <Accounts
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {!isAuthenticated && (
                  <Route
                    path="/forgotPassword"
                    element={
                      <ForgotPW
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/withdraw/add/bank"
                    element={<WithdrawBankAdd />}
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/withdraw/add/paystack"
                    element={<WithdrawPaystackAdd />}
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/withdraw/paypal/:id"
                    element={
                      <WithdrawPaypal
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/withdraw/bank/:id"
                    element={
                      <WithdrawBank
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/withdraw/paystack/:id"
                    element={
                      <WithdrawPaystack
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {!isAuthenticated && (
                  <Route
                    path="/password-reset/:token"
                    element={<ResetPassword />}
                  />
                )}
                <Route
                  path="/contract"
                  element={
                    <Contract
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                    />
                  }
                />
                <Route
                  path="/beats/:id"
                  element={
                    <Beat
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                {/* TODO: */}
                <Route
                  path="/albums/:id"
                  element={
                    <Album
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />

                <Route
                  path="/albums/:id/comments"
                  element={
                    <Album
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/music/:id"
                  element={
                    <Music
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/music/:id/comments"
                  element={
                    <Music
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/beats/:id/comments"
                  element={
                    <Beat
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/producers/:id"
                  element={
                    <Producer
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />

                <Route
                  path="/artists/:id"
                  element={
                    <Artist
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      updateCartCounter={updateCartCounter}
                      setUpdateCartCounter={setUpdateCartCounter}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                {isAuthenticated && (
                  <Route
                    path="/playlists"
                    element={
                      <Playlists
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/purchases/:type"
                    element={
                      <Purchases
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/messages/"
                    element={
                      <Messages
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/messages/:chatId"
                    element={
                      <Messages
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                <Route
                  path="/cart"
                  element={
                    <CartOverview
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                <Route
                  path="/checkout"
                  element={
                    <Checkout
                      setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                      handleLocalCart={handleLocalCart}
                      handleCart={handleCart}
                    />
                  }
                />
                {isAuthenticated && (
                  <Route
                    path="/update/beat/:id"
                    element={
                      <UpdateBeat
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/update/album/:id"
                    element={
                      <EditAlbum
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/update/song/:id"
                    element={
                      <EditSong
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        user={user}
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/playlists/:id"
                    element={
                      <PlaylistLanding
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        updateCartCounter={updateCartCounter}
                        setUpdateCartCounter={setUpdateCartCounter}
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                <Route path="/terms" element={<Terms />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                {isAuthenticated && (
                  <Route
                    path="/bids"
                    element={
                      <BeatsBid
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                        updateCartCounter={updateCartCounter}
                        setUpdateCartCounter={setUpdateCartCounter}
                        handleLocalCart={handleLocalCart}
                        handleCart={handleCart}
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/wallet"
                    element={
                      <Wallet
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}
                {isAuthenticated && (
                  <Route
                    path="/withdraw/list"
                    element={
                      <Withdrawals
                        setShowUnauthenticatedPopup={
                          setShowUnauthenticatedPopup
                        }
                      />
                    }
                  />
                )}

                <Route path="*" element={<Navigate to={"/"} />} />
              </Routes>
            </div>
            {/* {playerState.currentItemIndex !== null && ( */}
            <PlayerNew
              setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
              handleCart={handleCart}
              handleLocalCart={handleLocalCart}
              setUpdateCartCounter={setUpdateCartCounter}
            />
            {/* )} */}
            <Popup
              className={`add-queue-popup ${true ? "add-queue-player-popup" : ""
                }`}
              modal
              open={showUnauthenticatedPopup}
              onOpen={() => {
                setTimeout(() => {
                  setShowUnauthenticatedPopup(false);
                }, 1500);
              }}
            >
              Hello
            </Popup>

            <Footer />
          </NavigationProvider>
        </div>
      </Router>
    </>
  );
}

export default App;
